<template>
  <div class="container-autocomplete">
  <div class="row ">
    <div class="col ">
                  <div class="input-group mb-3" >
                    <vue-bootstrap-typeahead 
                    
                      v-debounce:500.lock="search"
                      v-model="query"
                      :data="resultados"
                      @hit="onSubmit"
                      size="lg"
                      placeholder="Digite o nome da receita aqui..."
                      :maxMatches="limiteResultados"
                      style="width:80%;"
                      id="campo-busca-receita"
                      inputClass="campo-busca-receita"
                      ref="typeahead"
                      @input="atualizarNome"
                    />

                    <!--<input type="text" class="form-control input-busca" aria-label="Ingredientes">-->
                    <div class="input-group-append" style="cursor:pointer;" v-scroll-to="'#resultados'">
                      <span @click="buscar" class="input-group-text"> <img  src="/img/magnifying-glass-4x.png"  alt="Lupa" /></span>
                    </div>
                  </div>
</div>
</div>
  </div>
</template>

<script>
import servidor from '@/servidor'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: 'AutocompleteReceita',
  components: {VueBootstrapTypeahead},
  props: {
    receita: String,
    url:String
  },
  data(){
    return {
      limiteResultados:20,
      resultados: [],
      query: '',
      receitas:[]
      
     
    }
  },
  methods:{
    limpar(){
      this.$refs.typeahead.inputValue = "";
    },
    buscar(){
      this.$emit('buscarInformacao',{});
    },
    atualizarNome(){
      this.$emit('update:receita', this.query);
    },
     search() {
       if(this.query.length > 1){
        let formData = new FormData();
        formData.append('query',this.query);
        fetch(servidor+this.url,{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            this.resultados = data;
          })
       }
    },

    onSubmit(result) {
      if(result != undefined){
        this.receitas.push(result);
        this.$emit('update:receita', result);
      }
    }
   
  },
  mounted(){
     
     let contexto = this;
      window.jQuery('.campo-busca-receita').keyup(function(e) {
        if(e.key === "Enter"){ contexto.$emit('buscarInformacao',{});}
      });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
