<template>
  <div class="container-autocomplete">
  <div class="row">
    <div class="col-sm-5 ">
                 
                    <vue-bootstrap-typeahead 
                      v-debounce:200.lock="search"
                      v-model="query"
                      :data="resultados"
                      @hit="onSubmit"
                      size="lg"
                      placeholder="Nome..."
                      :maxMatches="limiteResultados"
                      style=""
                      :inputClass="classe"
                      ref="typeahead"
                      @input="atualizarNome"
                    />

                   
</div>

<div class="col-sm-2">
  <svg @click="adicionar" style="cursor:pointer;" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-plus-square my-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                      </svg>

</div>

</div>
  </div>
</template>

<script>
import servidor from '@/servidor'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: 'AutocompleteCustom',
  components: {VueBootstrapTypeahead},
  props: {
    nome: String,
    url: String,
    classe: String,
    resultados: Array
   
  },
  data(){
    return {
      limiteResultados:50,
      query: ''
    }
  },
  methods:{
    atualizarNome(){
      this.$emit('update:nome', this.query);
    },
     search() {
       if(this.query.length > 1){
        let formData = new FormData();
        formData.append('query',this.query);
        fetch(servidor+'autocomplete/'+this.url,{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            this.$emit('update:resultados', data);
            //this.resultados = data;
          })
       }
       
    },

    onSubmit(result) {
      if(result != undefined && result != null){
        this.$emit('update:nome', result);
         this.$emit('pronto',this.query);
         this.$refs.typeahead.inputValue = "";
      }
    },
    adicionar(){
      if(this.query != ''){
         this.$emit('pronto',this.query);
         this.$refs.typeahead.inputValue = "";
      }
    }
   
  },
  mounted(){
     let contexto = this;
      window.jQuery('.'+contexto.classe).keyup(function(e) {
        if(e.key === "Enter" && contexto.query.length > 0){ 
          contexto.$emit('pronto',contexto.query);
          contexto.$refs.typeahead.inputValue = "";
          contexto.query = '';
        }
      });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
