<template>
  <div class="container-card-receita col-sm-6 col-lg-6 ">

    <div class="card" style="margin-bottom:20px;cursor:pointer;" v-on:click="abrirReceita" >
  <div class="card-header" :style="{background:cor}" style="border-bottom:2px solid #FFF !important;">

<svg v-if="showIconeDeletar" style="color:#FFF;cursor:pointer;" @click.stop="deletar" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash float-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>

    <h4 class="bold white">{{receita.nome}}</h4>
    <p v-if="receita.combinacao != 0" class="bold white">{{receita.combinacao}} Ingrediente(s) combinado(s)</p>
  </div>
  <div class="card-body" :style="{background:cor}">
    <div class="row">
      <div class="col-sm-7">
        <h4 class="bold white">{{receita.categoria}}</h4>
      </div>
      <div class="col-sm-5">
        <img :src="'/img/'+icone" class="img-fluid float-right" style="margin-top:-20px;"/>
      </div>
    </div>
    
    <div class="row">
      <div :style="{background:cor2}" class="col box-status text-center"> 
         <img src="/img/icon_det01.png" class="img-fluid img-icone-mobile" style=""/>
         <p class="white bold txt-icones">
        {{ (receita.dieta != '') ? receita.dieta:'N/A'}}</p> 
        </div>

      <div :style="{background:cor2}" class="col box-status text-center">
        <img src="/img/icon_det02.png" class="img-fluid img-icone-mobile" style=""/>
         <p class="white bold txt-icones">{{receita.rendimento}}<br>Pessoas</p>
          </div>

      <div :style="{background:cor2}" class="col box-status text-center">
        <img src="/img/icon_det03.png" class="img-fluid img-icone-mobile" style=""/>
         <p class="white bold txt-icones">{{receita.tempo}} Min</p>
       </div>

       <div :style="{background:cor2}" class="col box-status text-center"> 
         <img src="/img/icon_det04.png" class="img-fluid img-icone-mobile" style=""/>
         <p class="white bold txt-icones">{{receita.dificuldade}}</p> 
         </div>

       <div :style="{background:cor2}" class="col box-status text-center">
         <img src="/img/icon_det05.png" class="img-fluid img-icone-mobile" style=""/>
          <p class="white bold txt-icones">{{receita.custo}}</p>
           </div>
    </div>

  </div>

   <div class="card-footer" style="background:#FFF !important;">
    <p class=""><span class="bold">Autor(a):</span> {{receita.autor}}</p>
    <star-rating v-model="media" :read-only="true" :show-rating="false" :star-size="30" style="margin-top:-10px;" class="" /><small class="mb-3 d-inline-block">{{receita.qtd_avaliacao}} Avaliações</small>
    <router-link v-if="getRota ==false" :to="'/login'" class="btn btn-primary bt-visualizar" :style="{background:cor}">Faça login para visualizar</router-link>
    <router-link v-if="getRota == true" :to="'/visualizarReceita/'+receita.id" class="btn btn-primary bt-visualizar" :style="{background:cor}">Visualizar Receita</router-link>
  </div>
</div>




  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  name: 'CardReceita',
  components: {StarRating},
  props: {
    receita: Object,
    showIconeDeletar: Boolean
  },
  data(){
    return {
     cor:'',
     cor2:'',
     icone:'',
     media:0
     
    }
  },
  computed:{
    getRota(){
      return (this.$store.state.usuario == null) ? false : true;
    }
  },
  methods:{
    abrirReceita(){
      if(this.getRota){
        this.$router.push('/visualizarReceita/'+this.receita.id);
      }else{
        this.$router.push('/login');
      }

    },
    deletar(){
      this.$emit('deletarReceita',this.receita.id);
    },
    colorir(){
      this.media = parseInt(this.receita.nota);
      switch(this.receita.categoria){
       
         case 'Entradas':
          this.cor = "#17a959 !important";
          this.cor2 = "#085028 !important";
          this.icone = 'icon_categoria1.png';
        break;
         case 'Sopas, Cremes e Caldinhos':
          this.cor = "#eba800 !important";
          this.cor2 = "#da7400 !important";
          this.icone = 'icon_categoria2.png';
        break;
         case 'Aperitivos, Comidinhas e Lanches':
          this.cor = "#da7400 !important";
          this.cor2 = "#8f4e03 !important";
          this.icone = 'icon_categoria3.png';
        break;
        case 'Pratos Principais':
          this.cor = "#ec7500 !important";
          this.cor2 = "#f33b00 !important";
          this.icone = 'icon_categoria4.png';
        break;
         case 'Acompanhamentos':
          this.cor = "#a8be3d !important";
          this.cor2 = "#539400 !important";
          this.icone = 'icon_categoria5.png';
        break;
           case 'Sobremesas':
          this.cor = "#c34a84 !important";
          this.cor2 = "#6d0638 !important";
          this.icone = 'icon_categoria6.png';
        break;
         
           case 'Bolos, Doces e Pães':
          this.cor = "#c25abc !important";
          this.cor2 = "#583350 !important";
          this.icone = 'icon_categoria7.png';
        break;
         case 'Molhos e Caldos Clássicos':
          this.cor = "#e1331e !important";
          this.cor2 = "#be0200 !important";
          this.icone = 'icon_categoria8.png';
        break;
      }
    }
   
  },
  mounted(){
    this.colorir();
  },
  updated(){
    this.colorir();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.white{color:#FFF !important;}
.bt-visualizar{width:100%;border:none !important;}
.box-status{margin:0 4px;padding-top:10px;}
.txt-icones{font-size:0.9em;}

@media all and (max-width:500px){
.box-status img{height:25px !important;width:45px !important;}
.txt-icones{font-size:0.8em;}
}
</style>
