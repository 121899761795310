<template>
  <div class="container-busca">
<AutocompleteReceita :receita.sync="buscaReceita" @buscarInformacao="buscarReceitas" :url="getUrl" ref="nomeReceita"></AutocompleteReceita>
<hr>
  <div class="row">

    <div class="col-sm-3">

       <div class="form-group ">
              <label class="">Categoria</label>
              <select name="categoria" class="form-control" v-model="categoria">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="Entradas">Entradas</option>
						<option value="Sopas, Cremes e Caldinhos">Sopas, Cremes e Caldinhos</option>
						<option value="Aperitivos, Comidinhas e Lanches">Aperitivos, Comidinhas e Lanches</option>
						<option value="Pratos Principais">Pratos Principais</option>
						<option value="Acompanhamentos">Acompanhamentos</option>
						<option value="Sobremesas">Sobremesas</option>
						<option value="Bolos, Doces e Pães">Bolos, Doces e Pães</option>
						<option value="Molhos e Caldos Clássicos">Molhos e Caldos Clássicos</option>
              </select>
          </div>

           <div class="form-group">
              <label class="">Dieta</label>
              <select name="dieta" class="form-control" v-model="dieta">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option v-for="(item,index) in dietas" :key="index"  :value="item.nome">{{item.nome}}</option> 
              </select>
          </div>
                 
   </div><!-- col -->



   <div class="col-sm-3">

       <div class="form-group">
              <label class="">Dificuldade</label>
              <select name="dieta" class="form-control" v-model="dificuldade">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="Fácil">Fácil</option>
                  <option value="Média">Média</option>
                  <option value="Difícil">Difícil</option>
              </select>
          </div>

           <div class="form-group">
              <label class="">Custo aproximado</label>
              <select name="custo" class="form-control" v-model="custo">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="$">$</option>
                  <option value="$$">$$</option>
                  <option value="$$$">$$$</option>
                  <option value="$$$$">$$$$</option>
                  <option value="$$$$$">$$$$$</option>
              </select>
          </div>

                 
   </div><!-- col -->

 <div class="col-sm-3">

      <div class="form-group">
              <label class="">Tempo de preparo</label>
              <div class="input-group mb-3">
                <input class="form-control" min="0" name="tempo" type="number" v-model="tempo"/>
                <div class="input-group-append">
                      <span class="input-group-text">Min</span>
                    </div>
              </div>
          </div>     

        <div class="form-group">
              <label class="">Rendimento</label>
              <div class="input-group mb-3">
                <input class="form-control" min="0" name="rendimento" type="number" v-model="rendimento"/>
                <div class="input-group-append">
                      <span class="input-group-text">Pessoas</span>
                    </div>
              </div>
          </div>     
                 
   </div><!-- col -->

   <div class="col-sm-3">

      <div class="form-group">
              <label class="">Classificação</label>
              <select name="classificacao" class="form-control" v-model="classificacao">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="1.0">1.0</option>
                  <option value="2.0">2.0</option>
                  <option value="3.0">3.0</option>
                  <option value="4.0">4.0</option>
                  <option value="5.0">5.0</option>
              </select>
          </div>

          <div class="form-group">
              <label class="">Exige pré-preparo</label>
              <select name="preparo" class="form-control" v-model="preparo">
                 <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
              </select>
          </div>

      
                 
   </div><!-- col -->




<div v-if="showFiltroStatus" class="col-sm-3">

       <div  class="form-group">
              <label class="">Status</label>
              <select name="status" class="form-control" v-model="status">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="Rascunho">Rascunho</option>
                  <option value="Analise">Em Análise</option>
                  <option value="Edicao">Em Edição pela Tarimba</option>
                  <option value="Publicado">Publicado</option>
                  <option value="Negado">Negado</option>
              </select>
          </div>

      
                 
   </div><!-- col -->

   <div v-if="showIngredientes" class="col-sm-8">

      <div class="form-group">
              <label class="">Busca por ingrediente</label>
            <AutocompleteCustom :resultados.sync="ingredientesMatches" :nome.sync="ingredienteSelecionado" @pronto="addIngrediente" url="ingredientesComReceita" classe="autocompleteIngredientesBusca"></AutocompleteCustom>
 <br>
                 <div v-if="showMessageAutocomplete" class="alert alert-warning" role="alert">
                    <button type="button" style="font-size:0.9em;" class="close" @click="fecharBoxIngrediente">X</button>
                 <strong>Atenção!</strong> É obrigatório selecionar seu ingrediente na lista de opções que aparece logo abaixo do campo de busca após digitar as primeiras letras.
                </div>

                 <button style="margin-right:3px;margin-bottom:5px;background:#F18800 !important;border-color:#F18800;" type="button" class="btn btn-sm btn-primary" v-for="(item,index) in ingredientes" :key="'ing'+index" @click="removerIngrediente(index)">
                    {{item}} <span class="badge badge-light">X</span>
                  </button>

          </div>

      
                 
   </div><!-- col -->


</div>
<hr>
   
   <div class="row">
     <div class="col-sm-4">
        <button v-scroll-to="'#receitasCriadas'" @click="buscarReceitas" class="btn btn-light btn-block btn-search bold justify-content-center">APLICAR FILTROS</button>
     <br>
     </div>
      <div class="col-sm-3">
        <button  @click="limparFiltos" class="btn btn-light btn-block btn-search bold justify-content-center">LIMPAR FILTROS</button>
     <br>
     </div>
   </div>

  </div>
</template>

<script>
import servidor from '@/servidor'
import AutocompleteReceita from '@/components/AutocompleteReceita'
import AutocompleteCustom from '@/components/AutocompleteCustom'

export default {
  name: 'Busca',
  components: {AutocompleteReceita, AutocompleteCustom},
  props: {
    filtros: FormData,
    showIngredientes:Boolean,
    showFiltroStatus:Boolean,
    urlNome:String,

  },
  data(){
    return {
      buscaReceita:'',
      categoria:'',
      dieta:'',
      dificuldade:'',
      custo:'',
      tempo:'',
      rendimento:'',
      classificacao:'',
      status:'',
      preparo:'',
      dietas:[],
      ingredientesMatches:[],
      ingredientes:[],
      ingredienteSelecionado:'',
      showMessageAutocomplete:false
    }
  },
  methods:{
    limparFiltos(){
      this.classificacao = '';
      this.rendimento = '';
       this.preparo = '';
      this.ingredientes = [];
      this.dieta = '';
      this.categoria = '';
      this.dificuldade = '';
      this.custo = '';
      this.tempo = '';
      this.$refs.nomeReceita.limpar();
    },
     enviarPalavra(palavra) {
        let formData = new FormData();
        formData.append('nome',palavra);
        fetch(servidor+'endpoint/palavras',{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          });
     },
     fecharBoxIngrediente(){
      this.showMessageAutocomplete = false;
    },
    removerIngrediente(index){
      this.ingredientes.splice(index,1);
    },
    addIngrediente(){
      if(this.ingredienteSelecionado.length > 0){
        if(this.ingredientesMatches.indexOf(this.ingredienteSelecionado) == -1){
          this.showMessageAutocomplete = true;
          //this.enviarPalavra(this.ingredienteSelecionado);
        }else{
           this.showMessageAutocomplete = false;
           this.ingredientes.push(this.ingredienteSelecionado);
        }
      }
    },
    getDietas() {
      return new Promise((resolve) => {
        fetch(servidor+'autocomplete/dietas')
          .then((response) => response.json())
          .then((data) => {
            resolve(data)
          })
      });
    },

   buscarReceitas(){
     this.$emit('buscar',this.query);
   }
   
  },
  computed:{
    getUrl(){
      return this.urlNome + this.$store.state.usuario.id;
    },
    query: function(){
      let filtro = new FormData();
      filtro.append('nome',this.buscaReceita);
      filtro.append('categoria',this.categoria);
      filtro.append('custo',this.custo);
      filtro.append('dificuldade',this.dificuldade);
      filtro.append('tempo',this.tempo);
      filtro.append('dieta',this.dieta);
      filtro.append('classificacao',this.classificacao);
      filtro.append('status',this.status);
      filtro.append('rendimento',this.rendimento);
      filtro.append('preparo',this.preparo);
      filtro.append('ingredientes',JSON.stringify(this.ingredientes));
      
      this.$emit('update:filtros', filtro);
      return filtro;
    }
  },
  mounted(){
    this.getDietas().then((response) => this.dietas = response);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-search{  background-color: #400041;
    border: none;
    color: #fff;}
</style>
